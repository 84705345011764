import React, { useEffect } from "react";
import { datadogLogs } from "@datadog/browser-logs";

import Routes from "./routes";
import { useConfig } from "./components/ConfigProvider";
import { Loading } from "./components/subcomponents/Loading";

export const App = () => {
  const version = process.env.REACT_APP_VERSION ?? "0.0.0-unknown";
  const {
    loading,
    stage,
    datadogLogsInitConfiguration,
    serviceApiUrl,
    datadogEnable,
  } = useConfig();
  const [datadogLoaded, setDatadogLoaded] = React.useState(false);

  useEffect(() => {
    if (version && stage && !loading && datadogEnable && !datadogLoaded) {
      datadogLogs.init({
        clientToken: "pub36690d63e0c7879075ef8cb433568530",
        site: "datadoghq.eu",
        forwardErrorsToLogs: true,
        service: "one",
        version,
        env: stage,
        sessionSampleRate: 100,
        ...datadogLogsInitConfiguration,
      });
      datadogLogs.setGlobalContextProperty("component", "frontend");
      setDatadogLoaded(true);
    }
  }, [
    version,
    stage,
    loading,
    serviceApiUrl,
    datadogEnable,
    datadogLoaded,
    datadogLogsInitConfiguration,
  ]);

  if (loading) {
    return <Loading />;
  }

  return <Routes />;
};

export default App;
